"use client";

import { useRouter } from "next/navigation";
// import Image from "next/image";
import React from "react";

import Button from "@/components/atoms/buttons/Button";
import styles from "./stp-welcome.module.scss";
// import PopularCourses from "../popular-courses";
import utils from "@/utils";

function STPWelcome(props) {
  const { title, img, startDate, type } = props;
  const router = useRouter();

  return (
    <section className={styles["container"]}>
      {/* <Image src={img} width={"240"} height={"500"} alt="course-thumbnail" /> */}
      <h1>
        <span>Welcome to</span>&nbsp;
        {title}
      </h1>
      <p>
        Class will start from{" "}
        <span>{startDate && utils.formatFullDate(new Date(startDate))}</span>
      </p>

      <Button
        name={`Explore ${type === "batches" ? "our free courses" : "your batch"}`}
        handleClick={() =>
          router.push(type === "batches" ? "/courses" : "/batches")
        }
      />

      {/* <div className={styles.CoursesSwiper}>
        <PopularCourses />
      </div> */}
    </section>
  );
}

export default STPWelcome;

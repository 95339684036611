"use client";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import { Icon } from "@iconify/react";

import styles from "./lecture.module.scss";

import VideoUpload from "./VideoUpload";
import services from "@/store/services";
import Components from "@/components";
import Image from "next/image";
import utils from "@/utils";
import apis from "@/api";

const specialCharPattern = /[^a-zA-Z0-9 ]/;

function LectureUpload({
  index,
  stageContent,
  stageIndex,
  updateContent,
  handlers,
  id,
  isDragging,
  handleAdd,
  cId,
  handleDelete,
  updateContentData,
  // setIsLoading,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isValid, setIsValid] = useState("");
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [fileNameModal, setFileNameModal] = useState(false);
  const [videoDetails, setVideoDetails] = useState({
    thumbnails: [],
    mp4video: "",
    videoSrc: "",
    currthumb: "",
    duration: null,
  });
  const dispatch = useDispatch();

  const handleValidate = () => {
    let isValid = true;

    if (stageContent.title.trim() === "") {
      setIsValid("title");
      isValid = false;
    } else if (stageContent.description.trim() === "") {
      setIsValid("description");
      isValid = false;
    } else if (
      !videoDetails.thumbnails ||
      videoDetails.thumbnails.length === 0 ||
      videoDetails.currthumb === ""
    ) {
      setIsValid("thumbnails");
      isValid = false;
    }
    // else if (stageContent.videoSrc.trim() === "") {
    //   setIsValid("resource");
    //   isValid = false;
    // }

    return isValid;
  };

  useEffect(() => {
    if (id && id !== "") {
      setIsCollapsed(true);
      if (isDragging) setIsCollapsed(true);
    } else setIsCollapsed(false);
  }, [isDragging]);

  // const onChange = (field) => {
  //   if (field?.thumbnails) {
  //     setThumbnailsState(field.thumbnails);
  //   }
  //   if (field?.currthumb) {
  //     setCurrThumbState(field.currthumb);
  //   }
  //   updateContent(stageIndex, index, {
  //     ...stageContent,
  //     ...field,
  //   });
  // };

  const onDrop = async (acceptedFiles) => {
    // Do something with the files
    if (acceptedFiles[0].type.includes("image")) {
      setIsLoading(true);
      const response = await apis.thumbnailUpload(acceptedFiles);
      // updateContent(stageIndex, index, {
      //   ...stageContent,
      //   thumbnails: [...stageContent.thumbnails, response],
      //   currthumb: response,
      // });
      setVideoDetails((prev) => ({
        ...prev,
        thumbnails: [...prev.thumbnails, response],
        currthumb: response,
      }));
      setIsLoading(false);
    } else {
      toast.warning("Selected file is not image!");
    }
  };

  const addLecture = () => {
    if (!handleValidate()) {
      dispatch(services.failureAlert("Please fill all the details"));
      return;
    }

    const data = {
      title: stageContent.title,
      about: stageContent.description,
      thumbnailImage: videoDetails.currthumb,
      videoSrc: videoDetails.videoSrc,
      resources: stageContent.resources,
      courseId: cId,
      duration: videoDetails.duration,
    };
    if (stageContent?.id && stageContent.id !== "") {
      updateContentData(data, "video", stageContent.id);
    } else {
      handleAdd(data, "video");
    }
  };

  const handleOutputs = (response, duration) => {
    let thumbnails = [];
    const showVideoUrl = utils.generateCloudUrl("video", response);
    const tmbs = utils.generateCloudUrl("thumbnail", response, 1);
    const hlsVideo = utils.generateCloudUrl("hls", response);
    thumbnails.push(tmbs);

    const data = {
      // ...stageContent,
      thumbnails,
      mp4video: showVideoUrl,
      videoSrc: hlsVideo,
      currthumb: thumbnails[0],
      duration: parseFloat(duration).toFixed(2),
    };

    // updateContent(stageIndex, index, {
    //   ...data,
    // });
    setVideoDetails((prev) => ({ ...prev, ...data }));
  };

  const handleHlsVideo = (file) => {
    try {
      var video = document.createElement(`video`);
      video.preload = "metadata";
      video.onloadedmetadata = async function () {
        window.URL.revokeObjectURL(video.src);

        if (video.duration < 10) {
          dispatch(services.warningAlert("video is too short [< 10 Seconds]"));
        }
        const duration = (video.duration / 60).toFixed(2);

        handleOutputs(file, duration);
      };
      //-> video.src = URL.createObjectURL(file);
      video.src = utils.generateCloudUrl("video", file);
      setFileNameModal(false);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (stageContent.id && stageContent?.videoSrc !== "") {
      const fileTitle = stageContent?.videoSrc
        .split("/")
        .pop()
        .replace(".m3u8", "");

      handleHlsVideo(fileTitle);
    }
  }, [stageContent.id]);

  return (
    <article className={styles.container}>
      <div className={styles.header}>
        <div className={styles.leftContent}>
          <button {...handlers}>
            <Icon icon="uil:draggabledots" className={styles.icon} />
          </button>
          <p>{stageContent.title === "" ? "Lecture" : stageContent.title}</p>
        </div>
        <button
          onClick={() => setIsCollapsed((prev) => !prev)}
          className={!isCollapsed ? styles["arrow-up"] : null}
        >
          <Icon icon="fe:arrow-down" className={styles["icon"]} />
        </button>
      </div>
      {!isCollapsed ? (
        <div
          className={`${styles["sub-container"]} ${isCollapsed ? styles["collapsed"] : null}`}
        >
          <div>
            <p>
              Video title <span className={styles.requiredField}>*</span>
            </p>
            <Components.Input
              type="text"
              placeholder="Enter a title"
              handleChange={(event) => {
                if (specialCharPattern.test(event.target.value)) {
                  setIsTitleValid(false);
                } else {
                  !isTitleValid && setIsTitleValid(true);
                  setIsValid("");
                }
                updateContent(stageIndex, index, {
                  ...stageContent,
                  title: event.target.value,
                });
              }}
              className={
                isValid === "title" ? styles["error"] : styles["input"]
              }
              value={stageContent.title}
            />
            {!isTitleValid && (
              <p className={styles.errorText}>
                *Your Title should only contain alpha numeric characters!
              </p>
            )}
          </div>

          <VideoUpload
            onCancel={() =>
              setVideoDetails({
                thumbnails: [],
                mp4video: "",
                videoSrc: "",
                currthumb: "",
                duration: null,
              })
            }
            videoDetails={videoDetails}
            // setVideoDetails={(val) =>
            //   updateContent(stageIndex, index, {
            //     ...stageContent,
            //     ...val,
            //   })
            // }
            // poster={currThumbState}
            setFileNameModal={setFileNameModal}
          />
          <div className={styles["thumbnails"]}>
            <h3>
              Upload Video Thumbnail{" "}
              <span className={styles.requiredField}>*</span>
            </h3>
            <div className={styles["box"]}>
              {/* {thumbnailsState?.length < 2 ? ( */}
              {videoDetails?.thumbnails?.length < 2 ? (
                <Dropzone onDrop={onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      className={styles["thumbnail-upload"]}
                    >
                      <input {...getInputProps()} />
                      <Icon icon={"mdi:add"} className={styles["icon"]} />
                    </div>
                  )}
                </Dropzone>
              ) : (
                <div
                  className={styles["thumbnail-upload"]}
                  onClick={() => {
                    // updateContent(stageIndex, index, {
                    //   ...stageContent,
                    //   currthumb:
                    //     stageContent.thumbnails?.[
                    //       stageContent?.thumbnails?.length - 1
                    //     ],
                    //   // currthumb: thumbnailsState?.[thumbnailsState?.length - 1],
                    // });
                    setVideoDetails((prev) => ({
                      ...prev,
                      currthumb:
                        videoDetails.thumbnails?.[
                          videoDetails.thumbnails?.length - 1
                        ],
                    }));
                  }}
                >
                  <Icon
                    icon={"material-symbols:delete"}
                    className={styles["icon"]}
                    onClick={() => {
                      const arr = videoDetails.thumbnails;
                      arr.pop();
                      // setThumbnailsState(arr);
                      // updateContent(stageIndex, index, {
                      //   ...stageContent,
                      //   thumbnails: arr,
                      // });
                      setVideoDetails((prev) => ({ ...prev, thumbnails: arr }));
                    }}
                  />
                  {videoDetails.thumbnails && (
                    <div className={styles.thumbnailImage}>
                      <Image
                        src={
                          videoDetails?.thumbnails?.[
                            videoDetails?.thumbnails?.length - 1
                          ]
                        }
                        width={500}
                        height={500}
                        alt="thumbnail"
                      />
                    </div>
                  )}
                  {videoDetails?.currthumb === videoDetails?.thumbnails[0] ? (
                    <Icon icon={"gg:check-o"} className={styles["selected"]} />
                  ) : null}
                </div>
              )}
              <div
                className={styles["thumbnail-upload"]}
                onClick={() => {
                  setVideoDetails((prev) => ({
                    ...prev,
                    currthumb: videoDetails.thumbnails[0],
                  }));
                }}
              >
                {/* {thumbnailsState ? ( */}
                {/* {thumbnailsState?.length ? ( */}
                {videoDetails?.thumbnails?.length ? (
                  <>
                    <div>
                      <Image
                        src={videoDetails?.thumbnails?.[0]}
                        width={500}
                        height={500}
                        alt="course upload thumbnail"
                      />
                    </div>
                    {videoDetails?.currthumb ===
                    videoDetails?.thumbnails?.[0] ? (
                      <Icon
                        icon={"gg:check-o"}
                        className={styles["selected"]}
                      />
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <Components.TextFormatter
            id={`${id}-${index}`}
            title={"Description"}
            value={stageContent.description}
            setValue={(val) =>
              updateContent(stageIndex, index, {
                ...stageContent,
                description: val,
              })
            }
            className={styles.videoDesc}
            validator={isValid === "description"}
            requiredField={true}
          />
          <div className={styles.resourse}>
            <p>Resourse</p>
            <Components.Input
              type="text"
              placeholder="Add resourse here"
              handleChange={(event) => {
                let updated = stageContent;
                updateContent(stageIndex, index, {
                  ...stageContent,
                  resources: event.target.value,
                });
                setIsValid("");
              }}
              className={
                isValid === "resource" ? styles["error"] : styles["input"]
              }
              value={stageContent.resources}
            />
          </div>
          <div className={styles.action}>
            {id && id !== "" ? (
              <Components.Button
                name={"Delete"}
                className={styles.cancel}
                handleClick={() => handleDelete(stageContent.id)}
              />
            ) : (
              <Components.Button
                name={"Cancel"}
                className={styles.cancel}
                handleClick={() => {
                  updateContent(stageIndex, index);
                }}
              />
            )}
            {id && id !== "" ? (
              <Components.Button
                name={"Update"}
                className={styles.add}
                handleClick={addLecture}
              />
            ) : (
              <Components.Button
                name={"Add Lecture"}
                className={styles.add}
                handleClick={addLecture}
              />
            )}
          </div>
        </div>
      ) : null}
      <Components.Modal
        showModal={fileNameModal}
        className={"passwordModal"}
        setShowModal={setFileNameModal}
      >
        <Components.UploadLinkModal
          handleClose={() => setFileNameModal(false)}
          // saveCallback={(filename) => validateVideo(filename)}
          saveCallback={(filename) => handleHlsVideo(filename)}
        />
      </Components.Modal>
    </article>
  );
}

export default React.memo(LectureUpload);

"use client";

import React, { useEffect, useState, memo } from "react";
import { useDispatch } from "react-redux";
import { Icon } from "@iconify/react";

import styles from "./assignment.module.scss";
import services from "@/store/services";
import Components from "@/components";

export default memo(function AssignmentUpload({
  cId,
  index,
  stageContent,
  stageIndex,
  updateContent,
  handlers,
  isDragging,
  handleAdd,
  handleDelete,
  updateContentData,
  stageId,
  id,
}) {
  const dropData = [
    // { value: "codingTest", label: "Coding Test" },
    { value: "mcq", label: "MCQ" },
  ];
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isValid, setIsValid] = useState("");
  const dispatch = useDispatch();
  const testLinkRegex =
    /^https:\/\/www\.testportal\.cipherschools\.com\/\?sid=/;

  const handleValidate = () => {
    let isValid = true;

    if (stageContent.title === "") {
      setIsValid("title");
      isValid = false;
    } else if (!stageContent?.attachment || !stageContent?.attachment?.length) {
      setIsValid("link");
      isValid = false;
    } else if (stageContent.description.length === 0) {
      setIsValid("description");
      isValid(false);
    } else if (stageContent.duration.length === 0) {
      setIsValid("duration");
      isValid(false);
    }
    return isValid;
  };

  useEffect(() => {
    if (id && id !== "") {
      setIsCollapsed(true);
      if (isDragging) setIsCollapsed(true);
    } else setIsCollapsed(false);
  }, [isDragging]);

  const addAssignment = () => {
    if (!handleValidate()) {
      dispatch(services.failureAlert("Please fill all the details"));
      return;
    }
    console.log({
      link: stageContent?.attachment,
      isValid: testLinkRegex.test(stageContent?.attachment[0]?.testLink),
      regex: testLinkRegex,
    });
    const data = {
      content: {
        title: stageContent.title,
        description: stageContent.description,
        attachment: stageContent.attachment,
        duration: stageContent.duration,
        cId,
      },
      type: stageContent.type,
      stageId,
    };

    if (stageContent?.id && stageContent.id !== "") {
      updateContentData(data, stageContent.type, stageContent.id);
    } else {
      handleAdd(data, stageContent.type);
    }
  };

  return (
    <article className={styles.container}>
      <div className={styles.header}>
        <div className={styles.leftContent}>
          <button {...handlers}>
            <Icon icon="uil:draggabledots" className={styles.icon} />
          </button>
          <p>{stageContent.title === "" ? "Assignment" : stageContent.title}</p>
        </div>
        <button
          onClick={() => setIsCollapsed((prev) => !prev)}
          className={!isCollapsed ? styles["arrow-up"] : null}
        >
          <Icon icon="fe:arrow-down" className={styles["icon"]} />
        </button>
      </div>
      {!isCollapsed ? (
        <div
          className={`${styles["sub-container"]} ${isCollapsed ? styles["collapsed"] : null}`}
        >
          <div className={styles.assignment}>
            <p>
              Tittle <span className={styles.requiredField}>*</span>
            </p>
            <Components.Input
              type="text"
              placeholder="Add resourse here"
              handleChange={(event) => {
                let updated = stageContent;
                updateContent(stageIndex, index, {
                  ...stageContent,
                  title: event.target.value,
                });
                setIsValid("");
              }}
              value={stageContent.title}
              className={
                isValid === "title" ? styles["error"] : styles["input"]
              }
            />
            <Components.DropDownInput
              options={dropData}
              className={styles["drop-down"]}
              label={"Assignment type"}
              onChange={(val) => {}}
              value={
                stageContent.id
                  ? dropData.find((item) => item.value === stageContent.type)
                  : dropData[0]
              }
              requiredField={true}
            />
            <div>
              <p>
                Test duration <span className={styles.requiredField}>*</span> ()
              </p>
              <Components.Input
                type="number"
                placeholder="eg: 1.40 implies 1 minute 30 and seconds"
                handleChange={(event) => {
                  updateContent(stageIndex, index, {
                    ...stageContent,
                    duration: event.target.value,
                  });
                  setIsValid("");
                }}
                value={stageContent?.duration}
                className={
                  isValid === "duration" ? styles["error"] : styles["input"]
                }
              />
            </div>
            <Components.TextFormatter
              setValue={(val) =>
                updateContent(stageIndex, index, {
                  ...stageContent,
                  description: val,
                })
              }
              className={styles.description}
              title={"Description"}
              requiredField={true}
              value={stageContent?.description}
              validator={isValid === "description"}
            />
            <p>
              Link <span className={styles.requiredField}>*</span> (Click
              <a
                href="https://www.admin.cipherschools.com/test-platform/actions/tests?type=add"
                target={"_blank"}
                style={{ color: "var(--brand-color)" }}
              >
                {" "}
                here{" "}
              </a>
              to create a test link)
            </p>
            <Components.Input
              type="text"
              placeholder="Add resourse here"
              handleChange={(event) => {
                updateContent(stageIndex, index, {
                  ...stageContent,
                  attachment: [{ testLink: event.target.value }],
                });
                setIsValid("");
              }}
              value={stageContent?.attachment[0]?.testLink ?? ""}
              className={
                isValid === "content" ? styles["error"] : styles["input"]
              }
            />
          </div>
          <div className={styles.action}>
            {id && id !== "" ? (
              <Components.Button
                name={"Delete"}
                className={styles.cancel}
                handleClick={() => handleDelete(stageContent.id)}
              />
            ) : (
              <Components.Button
                name={"Cancel"}
                className={styles.cancel}
                handleClick={() => {
                  updateContent(stageIndex, index);
                }}
              />
            )}
            {id && id !== "" ? (
              <Components.Button
                name={"Update Assingnment"}
                className={styles.add}
                handleClick={addAssignment}
              />
            ) : (
              <Components.Button
                name={"Add Assingnment"}
                className={styles.add}
                handleClick={addAssignment}
              />
            )}
          </div>
        </div>
      ) : null}
    </article>
  );
});

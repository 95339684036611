"use client";

import React, { useState, useEffect, useRef } from "react";

import styles from "./batch-calendar-info.module.scss";
import Button from "@/components/atoms/buttons/Button";
import { getReadableDate } from "@/utils/formatDate";
import utils from "@/utils";

function BatchCalendarModal(props) {
  const { closeHandler, title, description, start, end } = props;
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current && description) {
      descriptionRef.current.innerHTML = `${utils.linkify(description)}`;
    }
  }, [description]);

  return (
    <div className={styles["container"]}>
      <div className={styles.header}>
        <h2>{title}</h2>
        <p className={styles.dates}>
          {getReadableDate(start)} to {getReadableDate(end)}
        </p>
      </div>
      <article className={styles.content}>
        <div>
          <p ref={descriptionRef}></p>
        </div>
      </article>
      <div className={styles.footer}>
        <Button
          handleClick={closeHandler}
          name={"Close"}
          className={styles.closeBtn}
        />
      </div>
    </div>
  );
}
export default BatchCalendarModal;

"use client";

import React, { memo, useEffect, useState } from "react";
import { Icon } from "@iconify/react";

import services from "@/store/services";
import styles from "./article.module.scss";
import Components from "@/components";

export default memo(function ArticleUpload({
  cId,
  index,
  stageContent,
  stageIndex,
  updateContent,
  stageId,
  handlers,
  isDragging,
  handleAdd,
  updateContentData,
  handleDelete,
  id,
}) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isValid, setIsValid] = useState("");

  const handleValidate = () => {
    let isValid = true;
    if (stageContent.title.trim() === "") {
      setIsValid("title");
      isValid = false;
    } else if (stageContent.description.trim() === "") {
      setIsValid("description");
      isValid = false;
    } else if (stageContent.duration.trim() === "") {
      setIsValid("duration");
      isValid = false;
    }
    return isValid;
  };

  useEffect(() => {
    if (id && id !== "") {
      setIsCollapsed(true);
      if (isDragging) setIsCollapsed(true);
    } else setIsCollapsed(false);
  }, [isDragging]);

  const addArticle = () => {
    if (!handleValidate()) {
      dispatch(services.failureAlert("Please fill all the details"));
      return;
    }
    const data = {
      content: {
        title: stageContent.title,
        description: stageContent.description,
        attachment: stageContent.attachment,
        duration: stageContent.duration,
        courseId: cId,
      },
      type: "article",
      stageId,
    };

    if (stageContent?.id && stageContent.id !== "") {
      updateContentData(data, stageContent.type, stageContent.id);
    } else {
      handleAdd(data, "article");
    }
  };

  return (
    <article className={styles.container}>
      <div className={styles.header}>
        <div className={styles.leftContent}>
          <button {...handlers}>
            <Icon icon="uil:draggabledots" className={styles.icon} />
          </button>
          <p>{stageContent.title === "" ? "Article" : stageContent.title}</p>
        </div>
        <button
          onClick={() => setIsCollapsed((prev) => !prev)}
          className={!isCollapsed ? styles["arrow-up"] : null}
        >
          <Icon icon="fe:arrow-down" className={styles["icon"]} />
        </button>
      </div>

      <div
        className={`${styles["sub-container"]} ${isCollapsed ? styles["collapsed"] : null}`}
      >
        <div>
          <p>
            Title <span className={styles.requiredField}>*</span>
          </p>
          <Components.Input
            type="text"
            placeholder="Enter a title"
            handleChange={(event) => {
              updateContent(stageIndex, index, {
                ...stageContent,
                title: event.target.value,
              });
              setIsValid("");
            }}
            value={stageContent?.title}
            className={isValid === "title" ? styles["error"] : styles["input"]}
          />
        </div>
        <div>
          <p>
            Read duration <span className={styles.requiredField}>*</span> ()
          </p>
          <Components.Input
            type="number"
            placeholder="eg: 1.40 implies 1 minute 30 and seconds"
            handleChange={(event) => {
              updateContent(stageIndex, index, {
                ...stageContent,
                duration: event.target.value,
              });
              setIsValid("");
            }}
            value={stageContent?.duration}
            className={
              isValid === "duration" ? styles["error"] : styles["input"]
            }
          />
        </div>
        <Components.TextFormatter
          title={"description"}
          requiredField={true}
          setValue={(val) => {
            try {
              updateContent(stageIndex, index, {
                ...stageContent,
                description: val,
              });
            } catch (error) {
              console.log({ error });
            }
          }}
          className={styles.description}
          value={stageContent?.description}
          validator={isValid === "description"}
        />
        <div className={styles.action}>
          {id && id !== "" ? (
            <Components.Button
              name={"Delete"}
              className={styles.cancel}
              handleClick={() => handleDelete(stageContent.id)}
            />
          ) : (
            <Components.Button
              name={"Cancel"}
              className={styles.cancel}
              handleClick={() => {
                updateContent(stageIndex, index);
              }}
            />
          )}
          {!stageContent._id ? (
            <Components.Button
              name={"Add Article"}
              className={styles.add}
              handleClick={addArticle}
            />
          ) : (
            <Components.Button
              name={"Update Article"}
              className={styles.add}
              handleClick={addArticle}
            />
          )}
        </div>
      </div>
    </article>
  );
});

"use client";

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { Icon } from "@iconify/react";
import Image from "next/image";

import styles from "./test-link.module.scss";
import services from "@/store/services";
import Components from "@/components";
import assets from "@/assets/images";
import apis from "@/api";

const aboutTestPlatform = [
  {
    id: 1,
    point:
      "AI-Powered Camera Proctoring for real-time monitoring and fraud detection.",
  },
  {
    id: 2,
    point:
      "Fraud detection with face recognition, audio monitoring, and browser restrictions.",
  },
  {
    id: 3,
    point:
      "Secure environment with encrypted data, multi-layer authentication, and device lockdown.",
  },
  {
    id: 4,
    point:
      "Post-test analysis with detailed behavior reports and reviewable recordings.",
  },
  {
    id: 5,
    point:
      "Customizable test settings for time limits, randomization, and security features.",
  },
];

const SecondScreen = (props) => {
  const {
    eligibilityCrietria,
    firstRender,
    setFirstRender,
    isLoading,
    prevResult,
  } = props;

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    }
  }, []);

  return (
    <article className={styles["second-screen"]}>
      <div className={styles["img-container"]}>
        <Image
          src={assets.TestEligibility}
          alt="test-image"
          width={400}
          height={400}
        />
      </div>
      <div className={styles["second-container"]}>
        <div className={styles["contents"]}>
          <h2 className={styles.title}>Test Eligibility</h2>
          {eligibilityCrietria?.map((item, i) => (
            <div className={styles.eligibility} key={i}>
              {isLoading ? (
                <Icon
                  icon="line-md:loading-loop"
                  className={styles.loadingIcon}
                />
              ) : (
                <Icon
                  icon={item.status ? "icons8:checked" : "ic:round-close"}
                  className={`${styles.icon} ${item.status ? styles["checked"] : styles["corssed"]}`}
                />
              )}

              <p>{item.data}</p>
            </div>
          ))}
        </div>
        <div className={styles["contents"]}>
          {prevResult ? (
            <>
              <h2 className={styles.title}>Previous attempt scores</h2>
              <div className={styles.eligibility}>
                <p>Marks obtained: {prevResult.obtainedScore}</p>
              </div>
              <div className={styles.eligibility}>
                <p>Total marks: {prevResult.totalScore}</p>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </article>
  );
};

const WarningScreen = (props) => {
  const { marksStats } = props;

  const avgPercentage = marksStats[0]?.totalMarks
    ? (marksStats[0]?.avgMarks / marksStats[0]?.totalMarks) * 100
    : 0;

  return (
    <section className={styles["warning-container"]}>
      <div className={styles["img-container"]}>
        <Image
          src={assets.LastAttempt}
          alt="test-image"
          width={400}
          height={400}
        />
      </div>
      <div className={styles["right"]}>
        <h3>Attention: Final Attempt for the test</h3>
        <ul>
          <li>
            Your current best score is{" "}
            <strong>
              {marksStats[0]?.currBestMarks}/{marksStats[0]?.totalMarks}
            </strong>
            .
          </li>
          <li>
            Your average score upto this stage is{" "}
            <strong>{marksStats[0]?.avgMarks}</strong>.
          </li>
          <li>
            To redeem the certificate for this course, your average score must
            be at least 50%.
          </li>
        </ul>
        <p>
          <p>
            {avgPercentage >= 50
              ? "Great job! You're on track to earn your certificate. Keep up the good work in this final attempt!"
              : "You're almost there! Improve your score in this attempt to qualify for the certificate."}
          </p>
        </p>
      </div>
    </section>
  );
};

function TestLinkModals(props) {
  const { content, marksStats, attemptsCount, closeHandler } = props;
  const { testInfo } = useSelector((state) => state.video);

  const [eligibilityCrietria, setEligibilityCrietria] = useState([
    { status: false, loading: false, data: "Checking eligibility" },
  ]);
  const [prevResult, setPrevResult] = useState(null);
  const [eligibleForTest, setEligibleForTest] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [firstRender, setFirstRender] = useState(true);
  const [screenNumber, setScreenNumber] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [progress, setProgress] = useState("0%");
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTestEligibility = async () => {
      setIsLoading(true);

      const response = await apis.getTestEligibility({
        courseId: testInfo?.courseId,
        stageId: testInfo?.stageId,
      });
      if (response?.data?.success === 200) {
        if (!response?.data?.result?.testTaken) {
          setEligibilityCrietria([
            {
              status: response?.data?.result?.stageCompleted,
              loading: false,
              data: "All stage content completed",
            },
          ]);
          setPrevResult(null);
          setEligibleForTest(response?.data?.result?.stageCompleted);
        } else {
          setPrevResult({
            totalScore: response?.data?.result?.total,
            obtainedScore: response?.data?.result?.score,
          });
          const scorePercentage =
            response?.data?.result?.score / response?.data?.result?.total;

          if (scorePercentage >= 0.5) {
            setEligibilityCrietria([
              {
                status: true,
                loading: false,
                data: "You have completed the test",
              },
            ]);
            setEligibleForTest(false);
          } else {
            setEligibilityCrietria([
              {
                status: response?.data?.result?.stageCompleted,
                loading: false,
                data: "All stage content completed",
              },
              {
                status: response?.data?.result?.testCooledDown,
                loading: false,
                data: "Wait for 24 hours before re-attempting again",
              },
            ]);
            setEligibleForTest(
              response?.data?.result?.testCooledDown &&
                response?.data?.result?.stageCompleted
            );
          }
        }
      } else if (response?.data?.success === 404) {
        setEligibilityCrietria([
          {
            status: false,
            loading: false,
            data: "You are not enrolled into the course!",
          },
        ]);
      } else {
        setEligibilityCrietria([
          {
            status: false,
            loading: false,
            data: "Something went wrong in fetching your eligibility, please contact support!",
          },
        ]);
      }
      setIsLoading(false);
    };

    fetchTestEligibility();
  }, [testInfo]);

  const fetchEnrollToTest = async () => {
    if (eligibleForTest) {
      setIsLoading(true);
      const sheduleId = jwtDecode(testInfo?.testLink.split("sid=")[1]);
      const response = await apis.enrollStudentToTest({
        courseId: testInfo?.courseId,
        scheduleId: sheduleId.id,
      });
      if (response?.status === 200) {
        dispatch(services.toggleTestLinkModal(false));
        window.open(testInfo?.testLink, "_blank");
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setDialogContent(content[0]);
    if (screenNumber === 1) {
      setProgress("50%");
    }
  }, [screenNumber]);

  const toggleReadMore = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleNextClick = () => {
    if (screenNumber < maxScreen) {
      setScreenNumber(screenNumber + 1);
    }
  };

  const handlePreviousClick = () => {
    if (screenNumber > 0) {
      setScreenNumber(screenNumber - 1);
    }
  };

  const getScreens = () => {
    const stripHtmlTags = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

    const plainTextDescription = stripHtmlTags(
      dialogContent?.description || ""
    );

    if (attemptsCount === 2) {
      return [
        <WarningScreen
          key="warning"
          marksStats={marksStats}
          closeHandler={closeHandler}
        />,
        <section key="content" className={styles["first-screen"]}>
          <div className={styles["img-container"]}>
            <Image
              src={assets.Test}
              alt="test-image"
              width={500}
              height={500}
            />
          </div>
          <div className={styles["sub-container"]}>
            <div className={styles.test}>
              <h2 className={styles.title}>{dialogContent?.title}</h2>
              <p className={styles.description}>
                {isExpanded
                  ? plainTextDescription
                  : `${plainTextDescription?.slice(0, 325)}...`}
                {plainTextDescription?.length > 325 && (
                  <span onClick={toggleReadMore}>
                    <strong> {isExpanded ? " Read Less" : "Read More"}</strong>
                  </span>
                )}
              </p>
            </div>
            <div className={styles["about-platform"]}>
              <h2 className={styles.title}>About the Test Platform</h2>
              <ul>
                {aboutTestPlatform.map((item) => (
                  <li key={item.id}>{item.point}</li>
                ))}
              </ul>
            </div>
          </div>
        </section>,
        <SecondScreen
          key="second"
          eligibilityCrietria={eligibilityCrietria}
          firstRender={firstRender}
          setFirstRender={setFirstRender}
          isLoading={isLoading}
          prevResult={prevResult}
        />,
      ];
    } else {
      return [
        <section key="first" className={styles["first-screen"]}>
          <div className={styles["img-container"]}>
            <Image
              src={assets.Test}
              alt="test-image"
              width={500}
              height={500}
            />
          </div>
          <div className={styles["sub-container"]}>
            <div className={styles.test}>
              <h2 className={styles.title}>{dialogContent?.title}</h2>
              <p className={styles.description}>
                {isExpanded
                  ? plainTextDescription
                  : `${plainTextDescription?.slice(0, 325)}...`}
                {plainTextDescription?.length > 325 && (
                  <span onClick={toggleReadMore}>
                    {isExpanded ? " Read Less" : "Read More"}
                  </span>
                )}
              </p>
            </div>
            <div className={styles["about-platform"]}>
              <h2 className={styles.title}>About the Test Platform</h2>
              <ul>
                {aboutTestPlatform.map((item) => (
                  <li key={item.id}>{item.point}</li>
                ))}
              </ul>
            </div>
          </div>
        </section>,
        <SecondScreen
          key="second"
          eligibilityCrietria={eligibilityCrietria}
          firstRender={firstRender}
          setFirstRender={setFirstRender}
          isLoading={isLoading}
          prevResult={prevResult}
        />,
      ];
    }
  };

  const screens = getScreens();
  const maxScreen = screens.length - 1;

  useEffect(() => {
    setProgress(`${(screenNumber / maxScreen) * 100}%`);
  }, [screenNumber, maxScreen]);

  return (
    <section className={styles.container}>
      <article className={styles.contentContainer}>
        {screens[screenNumber]}
      </article>

      <div className={styles["footer"]}>
        <div className={styles["progress-container"]}>
          <div
            className={styles["progressbar"]}
            style={{ width: progress }}
          ></div>
        </div>

        <div className={styles["btn-container"]}>
          {screenNumber === 0 ? (
            <>
              <Components.Button
                className={styles.closeBtn}
                dis
                name={"Close"}
                handleClick={closeHandler}
              />
              <Components.Button
                className={styles.btn}
                name={"Next"}
                handleClick={handleNextClick}
              />
            </>
          ) : screenNumber === 1 ? (
            <>
              {attemptsCount === 2 && (
                <>
                  <Components.Button
                    className={styles["prev-btn"]}
                    name={"Previous"}
                    disable={isLoading}
                    handleClick={handlePreviousClick}
                  />

                  <Components.Button
                    className={styles.btn}
                    name={"Next"}
                    handleClick={handleNextClick}
                  />
                </>
              )}

              {attemptsCount !== 2 && (
                <>
                  <Components.Button
                    className={styles["prev-btn"]}
                    name={"Previous"}
                    disable={isLoading}
                    handleClick={handlePreviousClick}
                  />
                  <Components.Button
                    className={styles.btn}
                    dis
                    disable={isLoading && eligibleForTest}
                    name={eligibleForTest ? "Start Test" : "Close"}
                    handleClick={() => {
                      eligibleForTest ? fetchEnrollToTest() : closeHandler();
                    }}
                    icon={
                      isLoading && (
                        <Icon
                          icon="line-md:loading-loop"
                          className={styles.loadingIcon}
                        />
                      )
                    }
                  />
                </>
              )}
            </>
          ) : (
            <>
              <Components.Button
                className={styles["prev-btn"]}
                name={"Previous"}
                disable={isLoading}
                handleClick={handlePreviousClick}
              />

              <Components.Button
                className={styles.btn}
                dis
                disable={isLoading}
                name={eligibleForTest ? "Start Test" : "Close"}
                handleClick={() => {
                  eligibleForTest ? fetchEnrollToTest() : closeHandler();
                }}
                icon={
                  isLoading && (
                    <Icon
                      icon="line-md:loading-loop"
                      className={styles.loadingIcon}
                    />
                  )
                }
              />
            </>
          )}
        </div>
      </div>
    </section>
  );
}
export default TestLinkModals;

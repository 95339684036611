//Cards
import UploadedVideoCard from "./cards/uploaded-video";
import SuggestionCard from "./cards/suggestion-video";
import ProfileDashCard from "./cards/profile-dash";
import SearchCreator from "./cards/search-creator";
import CertificateCard from "./cards/certificate";
import Languify from "./cards/course/languify";
import SearchCard from "./cards/search-result";
import FollowingCard from "./cards/following";
import ResourcesCard from "./cards/resources";
import CategoryCard from "./cards/category";
import ViewAllCard from "./cards/view-all";
import ResumeCard from "./cards/resumes";
import CourseCard from "./cards/course";
import BatchCard from "./cards/batch";
import TeamCard from "./cards/team";
//Panels
import CommentCard from "./sidebar/videosidebar/partials/comment";
import BottomNavbar from "./sidebar/leftsidebar/bottom-nav";
import ProfileSidebar from "./sidebar/profilesidebar";
import UploadSidebar from "./sidebar/uploadsidebar";
import BatchSidebar from "./sidebar/batchsidebar";
import VideoSidebar from "./sidebar/videosidebar";
import LeftSidebar from "./sidebar/leftsidebar";
import UserSidebar from "./sidebar/usersidebar";
//Modals/Overlay
import IntroModal from "./modals/intro";
import LoginModal from "./modals/auth";
import AddStage from "./modals/add-stage";
import AlumniModal from "./modals/alumni";
import RedeemModal from "./modals/redeem";
import PaidModal from "./modals/paid-course";
import Announcement from "./modals/announce";
import ErrorModal from "./modals/error-modal";
import FeedbackModal from "./modals/feedback";
import TestLinkModals from "./modals/test-link";
import InterestModal from "./modals/categories";
import AboutModal from "./modals/profile/about";
import ResourceModal from "./modals/live-links";
import VideoPageModal from "./modals/video-tour";
import PassModal from "./modals/password-change";
import AddResourceModal from "./modals/resource";
import PointsPolicy from "./modals/points-policy";
import SkillsModal from "./modals/profile/skills";
import CreatorReqModal from "./modals/creator-req";
import UploadLinkModal from "./modals/upload-link";
import TimePointModal from "./modals/cipher-points";
import AddAssignmentModal from "./modals/assignment";
import NoAttemptsModal from "./modals/no-attempts";
import AddProjectModal from "./modals/profile/project";
import PersonalDetails from "./modals/profile/personal";
import EducationModal from "./modals/profile/education";
import ConfirmDetailModal from "./modals/confirm-details";
import ExperienceModal from "./modals/profile/experience";
import CertificateReqModal from "./modals/certificate-req";
import PolicyFooter from "./modals/points-policy/partials";
import ConfirmationModal from "./modals/confirmation-modal";
import BatchCalendarModal from "./modals/batch-calendar-info";
import AddCertificateModal from "./modals/profile/certificates";
import PremiumCourseEnrollWarn from "./modals/premium-course-enroll";

//Molecules
import {
  AccordianHeader,
  AccordianList,
  AccordianHeader2,
  ResumeAccordian,
} from "./molecules/accordian";
import ConfirmationAlert from "./molecules/alerts/confirmation-alert";
import CelebrationAlert from "./molecules/alerts/coins-celebration";
import LoginDropMenu from "./molecules/drop-menu/LoginDropMenu";
import Notification from "./molecules/drop-menu/Notification";
import RedeamPoints from "./molecules/drop-menu/RedeamPoints";
import WarningAlert from "./molecules/alerts/warning";
import SmallVideo from "./molecules/small-player";
import DropDownMenu from "./molecules/drop-menu";
import BreakPoint from "./molecules/break-point";
import STPWelcome from "./molecules/stp-welcome";
import Accordion from "./molecules/accordian";
import Carousal from "./molecules/carousals";
import OtpInput from "./molecules/otp-input";
import HeatMap from "./molecules/heat-map";
import UnWrap from "./molecules/unwrap";
//Loader
import { PageLoader, ImageLoader, CircleLoader } from "./loaders";
//Atoms
import ToggleButton from "./atoms/buttons/ToggleBtn";
import LoadingBtn from "./atoms/buttons/LoadingBtn";
import TextFormatter from "./atoms/text-formatter";
import RatedSkillsInput from "./atoms/rated-skill";
import FollowBtn from "./atoms/buttons/FollowBtn";
import FileUploader from "./atoms/file_uploader";
import { SearchDropdown } from "./atoms/drop-down";
import SkillsInput from "./atoms/skills-input";
import MyDatePicker from "./atoms/date-picker";
import MediaInput from "./atoms/media-upload";
import DropDownInput from "./atoms/drop-down";
import VideoTimer from "./atoms/video-timer";
import Button from "./atoms/buttons/Button";
import DateInput from "./atoms/date-field";
import TextInput from "./atoms/text-input";
import PdfViewer from "./atoms/pdf-viewer";
import CheckBox from "./atoms/check-box";
import TextArea from "./atoms/text-area";
import DotMenu from "./atoms/dot-menu";
import Tooltip from "./atoms/tooltip";
import Filter from "./atoms/filter";
import Title from "./atoms/title";
import Input from "./atoms/input";
// Shared
import NotificationItem from "./shared/notification/index.js";
import AssignmentUpload from "./forms/assignment-upload";
import ProfileHeader from "./shared/profile-header";
import LectureUpload from "./forms/lecture-upload";
import ArticleUpload from "./forms/article-upload";
import Pagination from "./shared/pagination";
import MiniFooter from "./shared/miniFooter";
import CipherMap from "./shared/cipher-map";
import NoDataPage from "./shared/no-data";
import Dialogue from "./shared/dialogue";
import Wrapper from "./shared/wrapper";
import Navbar from "./shared/navbar";
import Avatar from "./shared/avatar";
import Footer from "./shared/footer";
import Modal from "./shared/modal";
//Guide
import POST_ENROLLMENT_TOUR_DATA from "./guide/video/post_enrollment";
import PRE_ENROLLMENT_TOUR_DATA from "./guide/video/pre_enrollment";
import CERTIFICATE_TOUR_DATA from "./guide/video/certificate";
import NOTES_TOUR_DATA from "./guide/video/notes";
import NewTourSteps from "./guide/NewTourSteps";
import ProfileSteps from "./guide/ProfileSteps";
import HomeSteps from "./guide/HomeSteps";
import Tour from "./guide/Tour";

import CardWrapper from "./hoc/CardWrapper";

const Components = {
  Accordion,
  BreakPoint,
  STPWelcome,
  CertificateCard,
  CategoryCard,
  Languify,
  CourseCard,
  SuggestionCard,
  Carousal,
  UnWrap,
  Input,
  ProfileDashCard,
  UploadedVideoCard,
  FollowingCard,
  SearchCard,
  SearchCreator,
  ViewAllCard,
  TeamCard,
  ResumeCard,
  BatchCard,
  ResourcesCard,

  BottomNavbar,
  LeftSidebar,
  UserSidebar,
  VideoSidebar,
  BatchSidebar,
  ProfileSidebar,
  UploadSidebar,
  SingleComment: CommentCard,
  DropDownMenu,
  SmallVideo,
  AddStage,
  IntroModal,
  LoginModal,
  PassModal,
  ProfileModal: PersonalDetails,
  InterestModal,
  FeedbackModal,
  TimePointModal,
  CreatorReqModal,
  UploadLinkModal,
  CertificateReqModal,
  AboutModal,
  ExperienceModal,
  EducationModal,
  AddCertificateModal,
  PremiumCourseEnrollWarn,
  ErrorModal,
  AddProjectModal,
  SkillsModal,
  PaidModal,
  AlumniModal,
  RedeemModal,
  TestLinkModals,
  VideoPageModal,
  ResourceModal,
  AddResourceModal,
  AddAssignmentModal,
  NoAttemptsModal,
  Announcement,
  ConfirmDetailModal,

  PointsPolicy,
  PolicyFooter,

  PdfViewer,
  LoginDropMenu,
  Notification,
  RedeamPoints,
  AccordianHeader,
  AccordianList,
  ResumeAccordian,
  AccordianHeader2,
  ConfirmationAlert,
  CelebrationAlert,
  WarningAlert,
  CardWrapper,
  HeatMap,
  OtpInput,
  //Shared
  NotificationItem,
  ProfileHeader,
  Pagination,
  CipherMap,
  Wrapper,
  NoDataPage,
  Tooltip,
  Avatar,
  Modal,
  Navbar,
  //Loaders
  PageLoader,
  ImageLoader,
  CircleLoader,
  //Dot Menu
  DotMenu,
  Title,
  CheckBox,
  DateInput,
  FollowBtn,
  LoadingBtn,
  MediaInput,
  SkillsInput,
  ToggleButton,
  MyDatePicker,
  TextFormatter,
  FileUploader,
  SearchDropdown,
  RatedSkillsInput,

  DropDownInput,
  VideoTimer,
  Button,
  TextInput,
  TextArea,
  Filter,
  ConfirmationModal,
  BatchCalendarModal,
  LectureUpload,
  ArticleUpload,
  AssignmentUpload,
  //footer
  MiniFooter,
  Dialogue,
  Footer,
  //guide
  POST_ENROLLMENT_TOUR_DATA,
  PRE_ENROLLMENT_TOUR_DATA,
  CERTIFICATE_TOUR_DATA,
  NOTES_TOUR_DATA,
  NewTourSteps,
  ProfileSteps,
  HomeSteps,
  Tour,
};

export default Components;

"use client";

import React, { useEffect, useState, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

import NoteField from "./partials/NoteField";
import NoteCard from "./partials/NoteCard";
import styles from "./notes.module.scss";
import Header from "./partials/Header";
import services from "@/store/services";
import Components from "@/components";
import sk from "@/skeletons";
import apis from "@/api";

const SortOption = [
  {
    key: 0,
    title: "recent",
  },
  {
    key: 1,
    title: "sequence",
  },
];

const NotesList = (props) => {
  const { sortMethod, videoId, load, handleDelete, setNoteInfo, setLoad } =
    props;
  const [notesList, setNotesList] = useState(null); //null||[]
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const fetchVideoNotes = useCallback(
    async (page) => {
      setLoading(true);
      const response = await apis.getVideoNotes(
        videoId,
        sortMethod.toLocaleLowerCase(),
        page,
        15
      );
      if (response.status === 200) {
        setNotesList(response.data?.result);
      }
      setLoading(false);
    },
    [videoId, sortMethod, load]
  );

  useEffect(() => {
    fetchVideoNotes(1);
  }, [fetchVideoNotes]);

  const handleLoadMore = async () => {
    setLoading(true);
    const data = await fetchVideoNotes(page + 1);
    if (data?.success === 200) {
      setPage((prev) => prev + 1);
      setNotesList((prev) => ({
        ...data?.result,
        data: [...prev.data, ...data?.result?.data],
      }));
    }
    setLoading(false);
  };

  return (
    <InfiniteScroll
      dataLength={notesList && notesList?.data?.length} //This is important field to render the next data
      next={handleLoadMore}
      hasMore={notesList && notesList?.totalCount > notesList?.data?.length}
      loader={<sk.RowCardSkeleton number={5} type="sk-side-video-card" />}
      className={styles["lists"]}
      id="next-videos"
    >
      {loading ? (
        <sk.RowCardSkeleton number={2} type="sk-side-video-card" />
      ) : notesList?.totalCount > 0 && !loading ? (
        notesList?.data?.map((note) => (
          <NoteCard
            key={note._id}
            handleDelete={handleDelete}
            load={() => setLoad((prev) => !prev)}
            handleEdit={(data) => setNoteInfo(data)}
            {...note}
          />
        ))
      ) : (
        <Components.NoDataPage data={"Start taking your notes here.."} />
      )}
    </InfiniteScroll>
  );
};

function Notes() {
  const { videoInfo, courseInfo, isEnrolled } = useSelector(
    (state) => state.video
  );
  const user = useSelector((state) => state.user.users);

  const [sortMethod, setSortMethod] = useState(SortOption[0].title);
  const [activeTab, setActiveTab] = useState("video"); //video||course
  const [currStage, setCurrStage] = useState(1);
  const [noteInfo, setNoteInfo] = useState(null);
  const [stages, setStages] = useState(null);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  let blobUrl;

  const fetchVideoTitles = useCallback(async () => {
    const res = await apis.videoTitles(courseInfo._id);
    if (res.status === 200) {
      setStages(res.data?.result);
    }
  }, [courseInfo, load]);

  useEffect(() => {
    if (user?.email) fetchVideoTitles();
  }, [fetchVideoTitles]);

  const handleDelete = async (noteId) => {
    const res = await apis.deleteNote(noteId);
    if (res.status === 200) {
      dispatch(services.successAlert("Note has deleted successfully!"));
      setLoad((prev) => !prev);
      return;
    } else {
      dispatch(
        services.failureAlert(
          res.data.error || res.data.result || "Something went wrong!"
        )
      );
      return;
    }
  };

  const getBlobData = async () => {
    const result = await apis.getNotesPdf(videoInfo.courseId);
    const blob = new Blob([result.data], { type: "application/pdf" });
    blobUrl = URL.createObjectURL(blob);
  };

  const handleDownload = async () => {
    await getBlobData();
    const link = document.createElement("a");
    link.href = blobUrl;
    blobUrl = undefined;
    link.download = `CipherSchools-${courseInfo.title}_${courseInfo.creatorInfo?.[0]?.name}.pdf`;
    dispatch(services.successAlert("Notes downloaded successfully 😀"));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className={styles["container"]}>
      <Header active={activeTab} setActive={setActiveTab} />
      {user?.email && isEnrolled ? (
        <main className={styles["lists"]}>
          {activeTab === "video" ? (
            <>
              <NoteField
                noteInfo={noteInfo}
                load={() => setLoad((prev) => !prev)}
              />
              <NotesList
                load={load}
                setLoad={setLoad}
                videoId={videoInfo._id}
                sortMethod={sortMethod}
                setNoteInfo={setNoteInfo}
                handleDelete={handleDelete}
              />
            </>
          ) : (
            <>
              {stages?.length ? (
                <div className={styles["link"]}>
                  <div className={styles["left"]}>
                    <a onClick={handleDownload} id="vd_Notes_Download_Pdf">
                      Download PDF
                    </a>
                    <Icon icon={"ph:arrow-right"} />
                  </div>
                  <div className={styles["right"]}>
                    <button>
                      {sortMethod}
                      <Icon icon={"mingcute:down-fill"} />
                    </button>
                    <div className={styles["vd-options-cont"]}>
                      <ul>
                        {SortOption.map((item, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() => setSortMethod(item.title)}
                            >
                              <p>{item.title}</p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={styles["lists"]} style={{ overflow: "auto" }}>
                {stages?.map((stage, index) => (
                  <Components.Accordion
                    key={index}
                    header={
                      <Components.AccordianHeader
                        stage={index + 1}
                        currStage={currStage}
                        title={stage.videoTitle}
                        className={styles["accordian"]}
                        handleChange={(e) => setCurrStage(e)}
                      />
                    }
                  >
                    <NotesList
                      load={load}
                      setLoad={setLoad}
                      videoId={stage.videoId}
                      sortMethod={sortMethod}
                      setNoteInfo={setNoteInfo}
                      handleDelete={handleDelete}
                    />
                  </Components.Accordion>
                ))}
              </div>
            </>
          )}
        </main>
      ) : user?.email ? (
        <Components.NoDataPage data={"Please enroll in this course!"} />
      ) : (
        <Components.NoDataPage data={"Please login or signup!"} />
      )}
    </section>
  );
}

export default Notes;

"use client";

import { Icon } from "@iconify/react";
import React from "react";

import Button from "@/components/atoms/buttons/Button";
import styles from "./no-attempts.module.scss";
import assets from "@/assets/images";
import Image from "next/image";

function NoAttemptsModal(props) {
  const { closeHandler, avgMarks, currBestMarks, totalMarks } = props;

  const avgPercentage =
    totalMarks && avgMarks ? (avgMarks / totalMarks) * 100 : 0;

  return (
    <section className={styles["container"]}>
      <div className={styles["cross-div"]} onClick={closeHandler}>
        <Icon icon="bitcoin-icons:cross-outline" className={styles.icon} />
      </div>
      <article>
        <Image
          src={assets.NoAttempts}
          alt="test-image"
          width={400}
          height={400}
        />

        <div className={styles["right"]}>
          <h3>Out of Attempts for the test</h3>

          <p>
            You have used all of your test attempts. Your best score for this
            stage is{" "}
            <strong>
              {currBestMarks}/{totalMarks}
            </strong>
            , and your overall average score is <strong>{avgMarks}</strong>.
            <br />
            {avgPercentage >= 50
              ? "Great job! You're on track to earn your certificate. Keep up the good work for the upcoming tests!"
              : "You're almost there! Improve your score in upcoming tests to qualify for the certificate."}
            <p>
              Note: You can only redeem the certificate if your average score
              across all tests is above 50%.
            </p>
          </p>

          <div className={styles["btn-div"]}>
            <Button name={"Close"} handleClick={closeHandler} />
          </div>
        </div>
      </article>
    </section>
  );
}

export default NoAttemptsModal;

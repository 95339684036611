"use client";

import React, { useContext, useEffect, useRef, useState } from "react";
import { useRouter, usePathname } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

import ProfileMenu from "@/data/ProfileMenu";
import styles from "../navbar.module.scss";
import SocketContext from "@/socket/Context";
import services from "@/store/services";
import Components from "@/components";
import Icons from "@/assets/icons";
import utils from "@/utils";
import apis from "@/api";
import Image from "next/image";

function RightComponent(props) {
  const user = useSelector((state) => state.user.users);
  const { socket } = useContext(SocketContext).SocketState;

  const [openNotification, setOpenNotification] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [watchPointMenu, setWatchPointMenu] = useState(false);
  const [theme, setTheme] = useState(utils.getTheme());
  const [count, setCount] = useState(0);
  const profileMenuRef = useRef(null);
  const router = useRouter();
  const pathname = usePathname();

  const dispatch = useDispatch();

  useEffect(() => {
    socket?.on("getNotifications", async (data) => {
      const res = await apis.getUnreadNotifications();
      if (res.data?.success === 200) setCount(res.data?.result);
    });
  }, [socket]);

  useEffect(() => {
    const getUnreadNotifications = async () => {
      if (user?.email) {
        const res = await apis.getUnreadNotifications();
        if (res.data?.success === 200) setCount(res.data?.result);
      }
    };

    getUnreadNotifications();
  }, [user]);

  return (
    <div className={styles.navRight}>
      <div className={styles["notification"]} id="nav-notification">
        <div className={styles["notification-box"]}>
          <Components.Tooltip title="Notification">
            <svg
              width="18"
              height="20"
              viewBox="0 0 28 29"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setOpenNotification((prev) => !prev)}
            >
              <path
                d="M11.6428 26.2204C11.8623 26.6044 12.185 26.9246 12.5772 27.1477C12.9693 27.3707 13.4166 27.4884 13.8722 27.4884C14.3277 27.4884 14.775 27.3707 15.1671 27.1477C15.5593 26.9246 15.882 26.6044 16.1015 26.2204M6.00393 9.82361C6.00393 7.81652 6.8329 5.89163 8.30848 4.4724C9.78406 3.05317 11.7854 2.25586 13.8722 2.25586C15.9589 2.25586 17.9602 3.05317 19.4358 4.4724C20.9114 5.89163 21.7404 7.81652 21.7404 9.82361C21.7404 18.6526 25.6745 21.1752 25.6745 21.1752H2.06982C2.06982 21.1752 6.00393 18.6526 6.00393 9.82361Z"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Components.Tooltip>
          <div className={styles["notification-count"]}>
            <span>{(user?.email && count > 10 ? "9+" : count) || 0}</span>
          </div>
        </div>
        {openNotification ? (
          user?.email ? (
            <Components.Notification close={() => setOpenNotification(false)} />
          ) : (
            <Components.LoginDropMenu
              close={() => setOpenNotification(false)}
            />
          )
        ) : null}
        <div className={styles["mobile-notification"]}>
          <svg
            width="15"
            height="19"
            fill="currentCiolor"
            viewBox="0 0 20 25"
            xmlns="http://www.w3.org/2000/svg"
            className="nav-notification-icon"
            onClick={() => router.push("/notifications")}
          >
            <path
              d="M19.0724 18.9615L17.4186 17.3077V10.8974C17.4186 6.96154 15.316 3.66667 11.6493 2.79487V1.92308C11.6493 0.858974 10.7904 0 9.72625 0C8.66215 0 7.80318 0.858974 7.80318 1.92308V2.79487C4.12369 3.66667 2.03395 6.94872 2.03395 10.8974V17.3077L0.380099 18.9615C-0.427593 19.7692 0.136509 21.1538 1.27753 21.1538H18.1622C19.316 21.1538 19.8801 19.7692 19.0724 18.9615ZM14.8545 18.5897H4.59805V10.8974C4.59805 7.71795 6.53395 5.12821 9.72625 5.12821C12.9186 5.12821 14.8545 7.71795 14.8545 10.8974V18.5897ZM9.72625 25C11.1365 25 12.2904 23.8462 12.2904 22.4359H7.16215C7.16215 23.1159 7.4323 23.7681 7.91316 24.249C8.39402 24.7299 9.04621 25 9.72625 25Z"
              fill="currentColor"
            />
          </svg>
          <span className={styles["notification-count"]}>
            {(user?.email && count > 10 ? "9+" : count) || 0}
          </span>
        </div>
      </div>
      <div className={styles["nav-avatar"]}>
        <Components.Tooltip
          title={user?.email ? `${user?.name}` : "Signin / Register"}
        >
          <div
            onClick={() =>
              user?.email
                ? setOpenProfileMenu((prev) => !prev)
                : dispatch(services.toggleSigninModal())
            }
          >
            <Components.Avatar
              src={user?.email ? user?.profileImage : "none"}
              alt={user?.name || "Signin/Register"}
              loader="vsm"
              errStyle={{ fontSize: "0.8rem" }}
            />
            {user?.email ? <p>Hey {user?.firstName}</p> : <p>Hey</p>}
          </div>
        </Components.Tooltip>
        <Components.DropDownMenu
          open={openProfileMenu}
          menuRef={profileMenuRef.current}
          setOpen={setOpenProfileMenu}
          menu={
            user?.isCreator
              ? ProfileMenu
              : ProfileMenu.slice(0, 5).map((item) => item)
          }
          type="nav"
        />
      </div>
      <div
        className={styles["watch-points"]}
        id="nav-cipher-points"
        onClick={() => setWatchPointMenu((prev) => !prev)}
      >
        <Components.Tooltip title={`CipherPoint: ${props.points}`}>
          <>
            <Image src={Icons.WatchPoints} alt="watchpoint-icon" />
            <h2>{utils.formatNumber(props.points)}</h2>
          </>
        </Components.Tooltip>
        {watchPointMenu ? (
          user?.email ? (
            <Components.RedeamPoints close={() => setWatchPointMenu(false)} />
          ) : (
            <Components.LoginDropMenu close={() => setWatchPointMenu(false)} />
          )
        ) : null}
      </div>
      {["/", "/alumni", "/about-us"].includes(pathname) ? (
        <div className={styles["nav-mode-null"]}></div>
      ) : (
        <Components.Tooltip title="Change Theme" arrow>
          <div id="nav-mode" className={styles["nav-mode"]}>
            <Icon
              className={styles.icon}
              icon="solar:sun-bold-duotone"
              onClick={() => {
                setTheme(theme === "light" ? "dark" : "light");
                dispatch(
                  services.toggleTheme(
                    theme === "light" ? "dark" : "light",
                    "fixed"
                  )
                );
              }}
            />
          </div>
        </Components.Tooltip>
      )}
    </div>
  );
}

export default RightComponent;

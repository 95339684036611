"use client";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRouter } from "next/navigation";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";

import styles from "./card.module.scss";
import Components from "@/components";
import utils from "@/utils";
import { PlayerAttributes } from "@/utilities/Player";

function NoteCard(props) {
  const { videoInfo, courseInfo } = useSelector((state) => state.video);
  const { handleDelete, handleEdit, ...note } = props;
  const [showMore, setShowMore] = useState(false);
  const router = useRouter();
  const note_ref = useRef(null);

  const handleTimestampClick = useCallback(() => {
    const ply = PlayerAttributes();
    const player = ply.getCurrentPlayer();

    if (note?.video?.seoTitle !== videoInfo.seoTitle && courseInfo?._id) {
      router.push(`/courses/${courseInfo.seoTitle}/${note.video.seoTitle}`, {
        state: { timestamp: note.timestamp },
      });
    } else {
      player.currentTime(note.timestamp);
    }
  }, []);

  useEffect(() => {
    if (note_ref.current) {
      note_ref.current.innerHTML = `<p>${utils.linkify(note.text)}</p>`;
    }
  }, [note.text, showMore]);

  return (
    <article className={styles["card"]}>
      <header>
        <h3>{note.title}</h3>
        <div className={styles["right"]}>
          <Icon icon={"bx:edit"} onClick={() => handleEdit(note)} />
          <Icon icon={"mdi:trash"} onClick={() => handleDelete(note._id)} />
        </div>
        <div className={styles["right"]}></div>
      </header>
      <div
        ref={note_ref}
        className={`${styles["notes_text"]} ${showMore && styles["expand"]}`}
      ></div>
      <footer>
        <button onClick={handleTimestampClick} id="vd_Notes_AddTimeStamp">
          <Icon icon={"carbon:play-filled"} />
          <span>{utils.secondsToHMS(note.timestamp) || "00:02:00"}</span>
        </button>
        <button
          onClick={() => setShowMore((prev) => !prev)}
          id="vd_Notes_MoreRead"
        >
          Read {showMore ? "Less" : "More"}
        </button>
      </footer>
    </article>
  );
}

export default NoteCard;
